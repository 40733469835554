.greenBadge {
    display: flex;
    height: 24px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(35, 168, 102, 0.15);

    >p {
        color: var(--success-800, #1A7E4D);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.redBadge {
    display: flex;
    height: 24px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(167, 50, 35, 0.15);

    >p {
        color: var(--error-700, #A73223);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.limit {

    text-align: left;
    width: 100%;

    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}