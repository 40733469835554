.clusters-cards-wrapper {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;

    position: relative;

    width: 100%;
    height: 100%;
}

.clustersCard {
    display: flex;
    width: 380px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--Light-500, #c6c8ce);
    background: #fff;

    /* Drop Shadow/Small */
    box-shadow: 0px 2px 2px 0px rgba(31, 39, 71, 0.1);

    cursor: pointer;

    &:hover {
        border-color: var(--userful-primary-light);
        box-shadow: 0px 2px 2px 0px rgba(31, 39, 71, 0.2);
    }

    > svg {
        display: flex;
        height: 64px;
        color: var(--primary-700, #234fdc);
    }
}

.clusterCardHeader {
    display: flex;
    height: 36px;
    padding-left: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Light-500, #c6c8ce);
}

.buttonLink {
    display: flex;
    width: 36px;
    height: 35px;
    border-top-right-radius: 8px;
    padding: 11px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-left: 1px solid var(--Light-500, #c6c8ce);
    flex-shrink: 0;

    &:hover {
        background: var(--Light-200, #f3f4f5);
    }

    > svg {
        color: #1f2747;
        text-align: center;
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 16px;
    }
}

.clusterCardSubHeader {
    display: flex;
    height: 36px;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Light-500, #c6c8ce);

    > svg {
        color: var(--Primary-600, #476ce2);
        text-align: center;
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > p {
        color: var(--Dark-400, #1f2747);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
    }
}

.clusterCardBody {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-bottom: 1px solid var(--Light-500, #c6c8ce);

    &:last-child {
        border-bottom: none;
    }
}

.clusterCardBodyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.clusterCardBodyColumn {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
        color: var(--Dark-100, #4b5168);
        text-align: center;
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > p {
        color: var(--Dark-100, #4b5168);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
