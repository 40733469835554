.standardSwitch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 15px;
    gap: 10px;

    border: 1px solid var(--Light-500, #c6c8ce) !important;

    border-radius: 0;
    border: none;

    min-width: 36px;
    width: 36px;
    height: 36px;

    border-radius: 8px;

    background: #ffffff;
    /* Light/500 */

    > svg {
        min-height: 16px;

        font-family: "Font Awesome 6 Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        color: #1f2747;
    }

    > .textInside {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;

        width: 275px;

        color: #1f2747;

        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }

    > svg {
        width: 14px;
        height: 16px;

        font-family: "Font Awesome 6 Pro";
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        /* Dark/100 */

        color: #4b5168;
    }

    &:hover {
        background: #f3f4f5 !important;
        border: 1px solid var(--Light-500, #c6c8ce);
    }

    &:active {
        background: #f3f4f5 !important;
        border: 1px solid var(--Light-500, #c6c8ce);

        box-shadow: none;
        outline: none;
    }

    &:focus {
        background: #f3f4f5 !important;
        border: 1px solid var(--Light-500, #c6c8ce);

        box-shadow: none;
        outline: none;
    }
}

.selectionBoxButton {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 11px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(15, 24, 61, 0.75);

    > svg {
        color: #fff;
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        width: 16px;
    }

    &:hover {
        background: #0f183db3;
    }

    &:hover {
        background: #0f183db3;
        border: none;
    }

    &:active {
        background: #0f183db3;
        border: none;
        box-shadow: none;
        outline: none;
    }

    &:focus {
        background: #0f183db3;
        border: none;
        box-shadow: none;
        outline: none;
    }
}

.dropdownMenuSwitchActiveSource {
    padding: 8px 0px;

    width: 260px;

    z-index: 500;

    background: #ffffff;
    border: 1px solid #c6c8ce;

    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 8px;

    > hr {
        height: 0;
        margin: 8px 0;

        border: 1px solid #c6c8ce;
    }

    > .dropdownItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        gap: 16px;

        height: 32px;

        background: #ffffff;

        color: #1f2747;

        > .iconInside {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            width: 32px;

            > svg {
                font-family: "Font Awesome 6 Pro";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;

                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;

                /* Dark/400 */

                color: #1f2747;
            }
        }

        > svg {
            font-family: "Font Awesome 6 Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;

            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            /* Dark/400 */

            color: #1f2747;
        }

        > .textInside {
            width: 300px;
            height: 17px;

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            color: #1f2747;
        }

        &:hover {
            background: #eaebee;

            color: #1f2747;
        }

        &:active {
            background: #f3f4f5;

            color: #1f2747;
        }
    }
}

.multiselectDropdownWrapper {
    width: 100%;
}
.multiselectDropdown {
    display: flex;
    height: auto !important;
    min-height: 36px;
    width: 100%;
    padding: 4px 8px !important;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--Light-500, #c6c8ce) !important;
    background: #fff;

    box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;

    > .textInside {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;

        width: 275px;

        color: #1f2747;

        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }

    > svg {
        width: 14px;
        height: 16px;

        font-family: "Font Awesome 6 Pro";
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        /* Dark/100 */

        color: #4b5168;
    }

    &:hover {
        border-radius: 8px;
        border: 1px solid var(--dark-100, #4b5168);
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;
    }

    &:focus {
        outline: none;
        border-radius: 8px;
        border: 1px solid var(--primary-500, #6c89e8);
        background: #fff;
    }

    &:disabled {
        box-shadow: none;
        border-radius: 8px;
        border: 1px solid var(--light-500, #c6c8ce);
        background: var(--light-200, #f3f4f5);
    }
}

.multiselectDropdownMenuSwitch {
    padding: 8px 0px;

    width: 528px;

    background: #ffffff;
    border: 1px solid #c6c8ce;

    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 8px;

    > hr {
        height: 0;
        margin: 8px 0;

        border: 1px solid #c6c8ce;
    }

    > .dropdownItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        gap: 16px;

        height: 32px;

        background: #ffffff;

        color: #1f2747;

        > svg {
            font-family: "Font Awesome 6 Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;

            /* Dark/400 */

            color: #1f2747;
        }

        > .textInside {
            width: 100%;
            height: 17px;

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            color: #1f2747;
        }

        &:hover {
            background: #eaebee;

            color: #1f2747;
        }

        &:active {
            background: #f3f4f5;

            color: #1f2747;
        }
    }
}

.multiselectContainer {
    display: flex;
    flex-wrap: wrap;

    gap: 4px;

    width: 100%;
}
