.multiStateWrapper {

    display: flex;
    flex-direction: row;

    >.mSbutton {

        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px 8px !important;
        gap: 8px;

        height: 36px;
        min-width: 36px;
        max-width: 250px;

        background: #FFFFFF;
        /* Light/500 */

        border: 1px solid #C6C8CE;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;

        p {
            margin: 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;

            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;

            margin: 0 8px 0 8px !important;

            color: #1F2747;
        }

        >.mSicon {
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height */

            padding: 0 !important;

            display: flex;
            align-items: center;
            text-align: center;
            color: #4B5168;
        }

        &:hover {
            background: #F3F4F5;
            cursor: pointer;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

    }

    >.active {
        background: #EDF0FC !important;
        border: 1px solid #234FDC;

        p {
            color: #234FDC;
        }

        >.mSicon {
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;
            color: #234FDC;
        }

        // &:last-child {
        //     border-radius: 0px 8px 8px 0px;
        // }
    }
}