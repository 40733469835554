.device-system-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}
.device-system-info-container-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}
.device-system-info-container-body {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    padding-bottom: 16px;
}
.info-container {
    display: flex;
    align-items: center;
    gap: 16px;

    > img {
        display: flex;
        width: 64px;
        height: 64px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--Light-300, #eaebee);
    }
}

.info-container-image {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--Light-300, #eaebee);

    > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}

.info-container-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    > .info-container-text-header {
        color: var(--Dark-400, #1f2747);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    > .info-container-text-description {
        color: var(--Dark-100, #4b5168);
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
