.navigationWrapper {
    display: flex;
    width: 320px;
    min-width: 320px;
    height: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap: 4px;

    border-right: 1px solid var(--Light-500, #c6c8ce);
    background: #fff;
}

.navigationSubItem {
    display: flex;
    height: 36px;
    padding: 8px 16px 8px 48px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;

    .navigationInsideItem {
        display: flex;
        align-items: center;
        gap: 16px;

        color: var(--Dark-100, #4b5168);

        > svg {
            text-align: center;
            font-family: "Font Awesome 6 Duotone";
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }

        > p {
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .navigationInsideItemActive {
        color: var(--Primary-700, #234fdc);
    }

    cursor: pointer;

    &:hover {
        background: var(--Light-200, #f3f4f5);
    }
}

.navigationItem {
    display: flex;
    height: 36px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;

    .navigationInsideItem {
        display: flex;
        align-items: center;
        gap: 16px;

        color: var(--Dark-100, #4b5168);

        > svg {
            text-align: center;
            font-family: "Font Awesome 6 Duotone";
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }

        > p {
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .navigationInsideItemActive {
        color: var(--Primary-700, #234fdc);
    }

    cursor: pointer;

    &:hover {
        background: var(--Light-200, #f3f4f5);
    }
}

.navigationItemActive {
    background: var(--Primary-100, #edf0fc);
}
