.ip {
    width: 4em;
    height: 2em;
}

.ip__track {
    stroke: #C6C8CE;
    transition: stroke var(--trans-dur);
}

.ip__worm1,
.ip__worm2 {
    animation: worm1 2s linear infinite;
}

.ip__worm2 {
    animation-name: worm2;
}

/* Animation */
@keyframes worm1 {
    from {
        stroke-dashoffset: 0;
    }

    50% {
        animation-timing-function: steps(1);
        stroke-dashoffset: -358;
    }

    50.01% {
        animation-timing-function: linear;
        stroke-dashoffset: 358;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes worm2 {
    from {
        stroke-dashoffset: 358;
    }

    50% {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: -358;
    }
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    /* border-radius: 50%;
    border: 2px solid #DFE0E3; */
}

.lds-ring-large {
    margin-bottom: 8px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #234FDC;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #234FDC transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.wrapper-loading {
    display: flex;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    flex-direction: row;
    align-items: center;

    &.not-fullscreen {
        width: inherit;
        height: inherit;
    }

    .loading {
        display: flex;

        flex-wrap: nowrap;
        align-items: center;
        align-content: space-around;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;

        gap: 8px;

        >.loadingText {
            display: flex;

            flex-wrap: nowrap;
            align-items: center;
            align-content: space-around;
            justify-content: center;

            flex-direction: column;

            gap: 4px;

            .loading-main-text {
                font-size: 16px;
            }

            .loading-animation-box {
                position: relative;
                width: 20px;
                height: 20px;
                background-color: #C6C8CE;
                margin-bottom: 8px;
            }

            .loading-animation-box-spinner {
                right: 0;
                position: relative;
                width: 10px;
                height: 10px;
                background-color: #234FDC;

                animation-name: move;
                animation-duration: 2s;
                animation-iteration-count: infinite;

                margin-bottom: 6px;
            }

            .loading-secondary-text {
                font-size: 14px;
                color: rgb(43, 43, 43);
            }
        }
    }
}

@keyframes move {
    0% {
        right: 0px;
        top: 0px;
    }

    25% {
        right: -10px;
        top: 0px;
    }

    50% {
        right: -10px;
        top: 10px;
    }

    75% {
        right: 0px;
        top: 10px;
    }

    100% {
        right: 0px;
        top: 0px;
    }
}