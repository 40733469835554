.cluster-body {
    display: flex;

    height: 100%;
    width: 100%;
}

.cluster-overview {
    width: 100%;
    // height: 228px;

    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;

    border-bottom: 1px solid var(--Light-500, #c6c8ce);
    background: #fff;

    // backdrop-filter: blur(2px);
}

.dropdown-input {
    width: 100%;
}

.dropdown-input-toggle {
    display: flex;
    height: 36px;
    width: 100%;
    padding: 8px 16px !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 8px;
    align-self: stretch !important;
    border-radius: 8px;
    border: 1px solid var(--Light-500, #c6c8ce) !important;
    background: #fff !important;

    box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset !important;

    color: var(--Dark-100, #4b5168) !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.dropdown-input-menu {
    padding: 8px 0px;

    width: 100%;

    background: #ffffff;
    border: 1px solid #c6c8ce;

    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 8px;

    > hr {
        height: 0;
        margin: 8px 0;

        border: 1px solid #c6c8ce;
    }

    > .dropdownItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        gap: 16px;

        height: 30px;

        background: #ffffff;

        color: #1f2747;

        > svg {
            font-family: "Font Awesome 6 Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;

            /* Dark/400 */

            color: #1f2747;
        }

        > .textInside {
            width: 300px;
            height: 17px;

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            color: #1f2747;
        }

        &:hover {
            background: #eaebee;

            color: #1f2747;
        }

        &:active {
            background: #f3f4f5;

            color: #1f2747;
        }
    }
}

.cluster-overview-container {
    display: flex;
    width: 307px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 9px;
    background: var(--Light-200, #f3f4f5);
}

.cluster-container-header-text {
    color: var(--Dark-400, #1f2747);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cluster-container-body {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
        color: var(--Dark-400, #1f2747);
        text-align: center;
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > p {
        color: var(--Dark-400, #1f2747);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
