.formWrapper {
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    background: #fff;

    .formHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        > h1 {
            color: var(--dark-400, #1f2747);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        > h3 {
            color: var(--dark-100, #4b5168);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 128.571% */
        }
    }

    .formBody {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .containerWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;

            h2 {
                color: var(--dark-400, #1f2747);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            h4 {
                color: var(--dark-100, #4b5168);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 128.571% */
            }

            h5 {
                color: var(--dark-400, #1f2747);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            h6 {
                color: var(--dark-100, #4b5168);
                text-align: right;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            > input {
                display: flex;
                height: 36px;
                padding: 8px 16px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid var(--light-500, #c6c8ce);
                background: #fff;

                box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;

                &:hover {
                    border-radius: 8px;
                    border: 1px solid var(--dark-100, #4b5168);
                    background: #fff;
                    box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;
                    outline: none;
                }

                &:focus {
                    border-radius: 8px;
                    border: 1px solid var(--primary-500, #6c89e8);
                    background: #fff;
                    outline: none;
                }
            }
        }
    }
}

.horizontalContainerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.formButtonsWrapper {
    display: flex;
    height: 56px;
    padding: 0px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 1px solid var(--light-500, #c6c8ce);
    background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.userfulFormFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 56px;
    gap: 8px;
    align-self: stretch;
    padding: 0 36px;

    border-top: 1px solid var(--light-500, #c6c8ce);
}

.userfulFormBody {
    display: flex;
    max-width: 600px;
    height: calc(100% - 56px);
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
    padding: 36px;
    gap: 32px;

    overflow-y: auto;

    background: #fff;

    > .userfulFormHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        cursor: default;

        .userfulFormHeaderTitle {
            color: var(--dark-400, #1f2747);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .userfulFormHeaderDescription {
            color: var(--dark-100, #4b5168);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }
    }

    > .userfulFormContents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .userfulFormContentsTitle {
            color: var(--dark-400, #1f2747);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .userfulFormContentsDescription {
            color: var(--dark-100, #4b5168);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
}

.userfulFormFieldTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;

    > svg {
        color: var(--dark-100, #4b5168);
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h5 {
        color: var(--dark-400, #1f2747);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    h6 {
        color: var(--dark-100, #4b5168);
        text-align: right;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.userfulFormRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    color: var(--dark-400, #1f2747);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.userfulFormRaw {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    color: var(--dark-400, #1f2747);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.userfulFormColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;

    .userfulFormColumnTitle {
        color: var(--dark-400, #1f2747);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.userfulFormSmallGap {
    gap: 4px;
}

.userfulForm8Gap {
    gap: 8px;
}

.userfulFormNormalGap {
    gap: 8px;
}

.userfulFormLargeGap {
    gap: 16px;
}

.userfulIcon {
    color: var(--Warning-800, #ffa200);
    text-align: center;
    font-family: "Font Awesome 6 Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card-icon {
    color: var(--Dark-100, #4b5168);
    text-align: center;
    font-family: "Font Awesome 6 Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    width: 16px;
}

.userfulIconButton {
    display: flex;
    width: 36px;
    height: 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;

    background-color: #fff;
    border: 1px solid var(--Light-500, #c6c8ce);

    &:hover {
        background: var(--light-200, #f3f4f5);
    }

    > svg {
        color: #1f2747;

        width: 16px;
        height: 16px;

        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.primaryUserfulButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--primary-700, #234fdc);

    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
        background: var(--primary-800, #1c3fb0);
    }

    &:disabled {
        opacity: 0.5;
    }
}

.warningUserfulButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #d13e2c;

    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
        background: #a73223;
    }

    &:disabled {
        opacity: 0.5;
    }
}

button {
    outline: none;
    border: none;
}

.secondaryUserfulButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--light-500, #c6c8ce);
    background: var(--light-200, #f3f4f5);

    color: var(--dark-400, #1f2747);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
        border: 1px solid var(--light-500, #c6c8ce);
        background: var(--light-300, #eaebee);
    }

    &:disabled {
        opacity: 0.5;
    }
}

.userfulInputFieldButton {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 11px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    cursor: pointer;

    &:hover {
        background: var(--light-200, #f3f4f5);
    }

    > svg {
        color: #1f2747;
        // height: 16px;
        width: 16px;

        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    border-top: 1px solid var(--Light-500, #c6c8ce);
    border-right: 1px solid var(--Light-500, #c6c8ce);
    border-bottom: 1px solid var(--Light-500, #c6c8ce);

    background: #fff;
}

.passwordContainer {
    display: flex;

    width: 100%;

    > .userfulInputField {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.userfulInputField {
    display: flex;
    height: 36px;
    width: 100%;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--light-500, #c6c8ce);
    background: #fff;

    box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;

    color: var(--dark-100, #4b5168);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
        border-radius: 8px;
        border: 1px solid var(--dark-100, #4b5168);
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;
    }

    &:focus {
        outline: none;
        border-radius: 8px;
        border: 1px solid var(--primary-500, #6c89e8);
        background: #fff;
    }

    &:disabled {
        box-shadow: none !important;
        border-radius: 8px;
        border: 1px solid var(--light-500, #c6c8ce);
        background: var(--light-200, #f3f4f5);
    }
}

.userfulFormInfoBlock {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--light-200, #f3f4f5);

    cursor: default;

    > svg {
        color: var(--dark-100, #4b5168);
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > p {
        flex: 1 0 0;
        color: var(--dark-100, #4b5168);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}

.interactive {
    cursor: pointer;
}

.spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-top: 2px solid #234fdc;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    /* Define the spin animation */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.errorText {
    color: var(--error-500, #da6556);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.userfulInputToCopy {
    display: flex;
    width: 100%;

    > p {
        display: flex;
        height: 36px;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-top: 1px solid var(--Light-500, #c6c8ce);
        border-left: 1px solid var(--Light-500, #c6c8ce);
        border-bottom: 1px solid var(--Light-500, #c6c8ce);
        background: var(--Light-200, #f3f4f5);

        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.1) inset;

        color: var(--Dark-100, #4b5168);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    > button {
        border: 1px solid var(--Light-500, #c6c8ce);
        display: flex;
        width: 36px;
        height: 36px;
        padding: 11px 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        background: #fff;

        &:hover {
            background: var(--light-200, #f3f4f5);
        }

        > svg {
            color: #1f2747;
            width: 16px;
            height: 16px;
            text-align: center;
            font-family: Font Awesome 6 Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.infoText {
    color: var(--Dark-100, #4b5168);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 16px;

    width: 100%;
    height: calc(100% - 112px);

    overflow-y: auto;

    > .userfulFormBody {
        overflow: unset;

        hr {
            background: var(--Light-500, #c6c8ce);
            width: 100%;
            height: 2px;
        }
    }
}

.rolesContainer {
    display: flex;
    flex-direction: column;

    gap: 8px;
}

.usersContainer {
    display: flex;
    flex-direction: column;

    padding-bottom: 4px;

    gap: 4px;
}

.userContainer {
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    height: 32px;

    gap: 8px;

    border-radius: 8px;

    background: #fff;

    &:hover {
        background: var(--Light-300, #eaebee);
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selectAllUsers {
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    gap: 8px;

    border-radius: 8px;

    background: #fff;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.userContainerActive {
    background: #edf0fc;

    &:hover {
        background: var(--Light-300, #edf0fc);
    }
}
