.emptyPlaceholderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 24px;

    width: 100%;
    height: 100%;

    overflow: hidden;

    > .emptyPlaceholderColumn {
        display: flex;

        width: 600px;

        flex-direction: column;
        align-items: center;
        gap: 8px;

        > .emptyPlaceholderTitle {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: normal;

            word-wrap: break-word;

            display: flex;
            align-items: center;
            text-align: center;
            flex-wrap: wrap;

            color: #1f2747;
        }

        > .emptyPlaceholderDescription {
            align-self: stretch;
            color: var(--dark-400, #1f2747);
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    > .emptyPlaceholderIcon {
        color: #1f2747;
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }
}
