.fieldColumnWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;
}

.flexWrap {
    flex-wrap: wrap;
}

.fieldRowWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.uploadFilesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;

    width: 528px;
    height: 208px;

    /* Light/500 */

    border: 1px dashed #c6c8ce;
    border-radius: 8px;

    &:hover {
        background: #f3f4f5;
        /* Dark/100 */

        border: 1px dashed #4b5168;
    }
}

.orContainer {
    width: 100%;
    height: 17px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    /* Dark/100 */

    color: #4b5168;
}

.selectionCard-active {
    > .imageWrapper {
        border: 1px solid #234fdc !important;
        background: #dae2f9 !important;
    }

    > .textWrapper {
        border-right: 1px solid #234fdc !important;
        border-left: 1px solid #234fdc !important;
        border-bottom: 1px solid #234fdc !important;
    }
}

.disabledButton {
    cursor: default !important;
    background: #f3f4f5;
    opacity: 0.5;
}

.selectionCard {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 32.291%;
    height: 140px;

    border-radius: 8px;

    cursor: pointer;

    filter: drop-shadow(0px 2px 2px rgba(31, 39, 71, 0.1));

    &:hover {
        > .imageWrapper {
            border-top: 1px solid #4b5168;
            border-right: 1px solid #4b5168;
            border-left: 1px solid #4b5168;
        }

        > .textWrapper {
            border-right: 1px solid #4b5168;
            border-left: 1px solid #4b5168;
            border-bottom: 1px solid #4b5168;
        }
    }

    > .imageWrapper {
        box-sizing: border-box;

        display: flex;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: 90px;

        background: #f7f9fa;

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        border: 1px solid #c6c8ce;

        > img {
            width: 48px;
            height: 48px;
        }
    }

    > .textWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 8px;
        gap: 10px;

        width: 100%;
        height: 50px;

        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        border-right: 1px solid #c6c8ce;
        border-left: 1px solid #c6c8ce;
        border-bottom: 1px solid #c6c8ce;

        background: #ffffff;

        > .text {
            height: 17px;
            width: 100%;

            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            text-align: center;

            /* Dark/400 */
        }
    }
}

.selectionCardSmall-active {
    border: 1px solid #234fdc !important;
    background: #dae2f9 !important;
}

.selectionCardSmall {
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    width: 68px;
    height: 68px;

    background: #ffffff;
    /* Light/500 */

    border: 1px solid #c6c8ce;
    /* Drop Shadow/Small */

    box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
    border-radius: 8px;

    &:hover {
        border: 1px solid #4b5168;
    }

    > img {
        width: 36px;
        height: 36px;
    }
}

.interactive {
    cursor: pointer;
}

.toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex;
    align-items: center;
    padding: 3px;
    gap: 10px;

    width: 32px;
    height: 16px;

    background: #c6c8ce;
    border-radius: 99px;

    > .toggleCircle {
        width: 12px;
        height: 12px;

        border-radius: 12px;
        background: #ffffff;

        box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
    }
}

.toggleText {
    height: 17px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Dark/400 */

    color: #1f2747;
}

.toggleActive {
    flex-direction: row-reverse;
    background: #23a866;
}

.uUpload {
    display: flex;
    gap: 36px;
    flex-direction: column;

    width: 100%;
}

.uploadCard {
}

.elementDown {
    display: flex;
    align-items: flex-end;

    height: 61px;
}

.longGap {
    gap: 16px;
}

.shortGap {
    gap: 4px;
}

.errorBorder {
    border: 1px solid #d13e2c !important;

    &:hover {
        border: 1px solid #d13e2c !important;
    }

    &:focus {
        border: 1px solid #d13e2c !important;
    }
}

.errorIcon {
    width: 12px;
    height: 12px;

    font-family: "Font Awesome 6 Pro";
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 12px;

    color: #d13e2c;
}

.errorMsg {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #d13e2c;
}

.checkbox {
    min-width: 16px;
    width: 16px !important;
    height: 16px !important;

    background: #ffffff;
    border: 1px solid #c6c8ce;

    box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
    border-radius: 4px;

    cursor: pointer;

    &:hover {
        border: 1px solid #4b5168;
    }

    > svg {
        width: 12px !important;
        height: 12px !important;
    }
}

.radioButton {
    width: 16px !important;
    height: 16px !important;

    background: #ffffff;
    border: 1px solid #c6c8ce;

    box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
    border-radius: 99px;

    cursor: pointer;

    &:hover {
        border: 1px solid #4b5168;
    }
}

.absolutePosition {
    position: absolute;

    width: 68px;
    height: 68px;

    padding: 4px;
}

.checked {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #234FDC;
    border: none;

    > svg {
        height: 8px;
        color: #ffffff;
    }

    &:hover {
        border: none;
    }
}

.mainText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Dark/400 */

    color: #1f2747;
}

.secondaryText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    display: flex;
    align-items: center;

    /* Dark/100 */

    color: #4b5168;
}

.uTextarea {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 100%;

    min-height: 60px;
    height: 60px;
    max-height: 144px;

    background: #ffffff;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1f2747;

    border: 1px solid #c6c8ce;
    box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
    border-radius: 8px;

    &:hover {
        background: #ffffff;

        border: 1px solid #4b5168;
        box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
        border-radius: 8px;
    }

    &:focus {
        background: #ffffff;

        border: 1px solid #6c89e8;
        border-radius: 8px;

        outline: none;
    }

    &:disabled {
        background: #f3f4f5;

        border: 1px solid #c6c8ce;
        border-radius: 8px;

        box-shadow: none;
        outline: none;
    }
}

.bottom {
    align-items: flex-end;
}

.disabledInput {
    background: #f3f4f5;

    border: 1px solid #c6c8ce7e;
    border-radius: 8px;

    box-shadow: none;
    outline: none;
}

.inputButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 8px 16px;
    gap: 8px;

    width: 100%;
    height: 36px;

    background: #f3f4f5;

    border: 1px solid #c6c8ce;
    border-radius: 8px;

    box-shadow: none;
    outline: none;
    border-radius: 8px;

    cursor: pointer;

    > .copyLinkText {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        display: flex;
        align-items: center;

        /* Dark/400 */

        color: rgba(0, 0, 0, 0.7);
    }

    > svg {
        height: 16px;

        font-family: "Font Awesome 6 Pro";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        color: #c6c8ce;
    }
}

.uInput {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 100%;
    height: 36px;

    background: #ffffff;

    border: 1px solid #c6c8ce;
    box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
    border-radius: 8px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1f2747;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Works for Firefox */
    .uInput[type="number"] {
        -moz-appearance: textfield;
    }

    &:hover {
        background: #ffffff;

        border: 1px solid #4b5168;
        box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
        border-radius: 8px;
    }

    &:focus {
        background: #ffffff;

        border: 1px solid #6c89e8;
        border-radius: 8px;

        outline: none;
    }

    &:disabled {
        background: #f3f4f5;

        border: 1px solid #c6c8ce;
        border-radius: 8px;

        box-shadow: none;
        outline: none;
    }
}

.numberInputWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px;

    width: 100%;
    height: 36px;

    > .iconButtonWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        min-width: 36px !important;
        width: 36px !important;

        min-height: 36px !important;
        height: 36px !important;

        background-color: white;
        border: 1px solid #c6c8ce;

        border-radius: 8px;

        &:hover {
            background: #f3f4f5;
        }

        &:active {
            background: #eaebee;
        }

        &:first-child {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        > svg {
            height: 16px;

            font-family: "Font Awesome 6 Pro";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;

            color: #1f2747;
        }
    }

    > .uInput {
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        width: 100%;
        height: 36px;

        background: #ffffff;

        border: 1px solid #c6c8ce;
        box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);

        border-radius: 0;

        &:hover {
            background: #ffffff;
            border: 1px solid #4b5168;
            box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);

            border-radius: 0;
        }

        &:focus {
            background: #ffffff;
            border: 1px solid #6c89e8;
            outline: none;

            border-radius: 0;
        }

        &:disabled {
            background: #f3f4f5;

            border: 1px solid #c6c8ce;
            border-radius: 8px;

            box-shadow: none;
            outline: none;
        }
    }
}

.uformDropdownButtonWithInput {
    width: 100%;

    padding: 0 !important;
    border: none;
    border-radius: 0;
    background-color: transparent;

    display: flex;
    flex-direction: row;

    cursor: pointer;

    &:hover {
        padding: none;
        border: none;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
    }

    &:focus {
        padding: none;
        border: none;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
    }

    &:active {
        padding: none;
        border: none;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
    }
}

.assetsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
    -ms-user-select: none;
    user-select: none;
}

.assetSelected {
    background: #c6c8ce !important;
}

.uUploadWrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.uUploadFilesWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    gap: 8px;

    width: 100%;

    > .source-form-wrapper {
        width: 100%;

        > .upload-files-area {
            width: 100%;
        }
    }
}

.button-wrapper-upload {
    display: flex;
    flex-direction: row-reverse;

    gap: 8px;
}

.assetsListWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: 100%;

    > .assetAllContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 8px;

        width: 528px;
        height: 36px;

        > .assetText {
            height: 17px;

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            color: #1f2747;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    > .assetContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 16px;

        background: #f3f4f5;
        border-radius: 8px;

        cursor: pointer;

        width: 528px;
        height: 36px;

        &:hover {
            background-color: #eaebee;
        }

        > .assetInnerContainer {
            display: flex;
            gap: 8px;
            align-items: center;

            > .assetText {
                width: auto;
                height: 17px;

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;

                color: #1f2747;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            > .assetSVG {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;

                width: 20px;
                height: 20px;

                > svg {
                    width: 12px;
                    height: 16px;

                    font-family: "Font Awesome 6 Pro";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    text-align: center;

                    color: #1f2747;
                }
            }
        }
    }
}

.UFormFullWidthWrapper {
    width: 100%;
}

.UFormFullHeightWrapper {
    height: 100%;
}
.disabled{
    opacity: 0.5;
    cursor: default !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }