.appStatus {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: #c6c8ce60;

    // z-index: 10;
}
