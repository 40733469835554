.constellationCard {
    position: relative;
    display: flex;
    max-width: 364px;
    min-width: 360px;
    height: 264px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid var(--light-500, #c6c8ce);
    background: #fff;

    /* Drop Shadow/Small */
    box-shadow: 0px 2px 2px 0px rgba(31, 39, 71, 0.1);

    cursor: pointer;

    &:hover {
        border-color: var(--userful-primary-light);
        box-shadow: 0px 2px 2px 0px rgba(31, 39, 71, 0.2);
    }

    > svg {
        display: flex;
        height: 64px;
        color: var(--primary-700, #234fdc);
    }

    .constellationCardTextWrapper {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 8px;

        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: normal;

        text-align: center; // Centers the text within the container

        > h1 {
            color: var(--dark-400, #1f2747);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        > h2 {
            color: var(--dark-400, #1f2747);
            font-family: Inter;
            font-size: 14px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        > h3 {
            color: var(--dark-400, #1f2747);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
