.device-body {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 24px;
    background: #fff;

    width: 100%;
    height: calc(100vh - 112px);

    overflow-y: auto;
}

.full-iframe-container{
    width: 100%;
    height: calc(100vh - 112px);
}