.appWrapper {
    display: flex;

    width: 100vw;
    height: calc(100vh - 56px);

    overflow-x: hidden;
    overflow-y: hidden;

    z-index: 0;
}

.contentWrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - 56px);
}

.currentTextSelected {

    padding: 4px 8px;
    border-radius: 8px;
    
    color: var(--Dark-100, #4b5168);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: default;
}

.currentTextSelectable {

    padding: 4px 8px;
    border-radius: 8px;

    color: var(--Primary-700, #234fdc);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;

    &:hover{
        background: var(--Primary-100, #EDF0FC);
    }
}

.horizontalWrapper {
    display: flex;
    width: 100%;

    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    background: #fff;

    &:first-child {
        border-bottom: 1px solid var(--light-500, #c6c8ce);
    }
}

.constellationCardsWrapper {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;

    position: relative;
    overflow-y: auto;
    
    width: 100%;
    height: 100%;
}

.actionsBar {
    display: flex;
    height: 56px;
    padding: 14px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--light-500, #c6c8ce);
    background: #fff;
}
