.iconButton {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 11px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;

    border: 1px solid var(--Light-500, #c6c8ce);
    background: #fff;

    outline: none;

    cursor: pointer;

    &:hover {
        background: var(--light-200, #f3f4f5);
    }

    > svg {
        color: #1f2747;
        // height: 16px;
        width: 16px;

        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.createButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--success-700, #23a866);

    border: none;
    outline: none;

    cursor: pointer;

    &:hover {
        background: var(--success-800, #1a7e4d);
    }

    > svg {
        color: #fff;
        height: 16px;
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    color: #fff;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.primaryButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;

    background: var(--primary-700, #234fdc);
    border: none;
    outline: none;

    cursor: pointer;

    &:hover {
        background: #1c3fb0;
    }

    > svg {
        color: #fff;
        height: 16px;
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    color: #fff;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.secondaryButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--light-500, #c6c8ce);
    background: var(--light-200, #f3f4f5);
    outline: none;

    cursor: pointer;

    &:hover {
        border: 1px solid var(--light-500, #c6c8ce);
        background: var(--light-300, #eaebee);
    }

    > svg {
        color: var(--dark-400, #1f2747);
        height: 16px;
        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    color: var(--dark-400, #1f2747);

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.icon {
    width: 20px;
    height: 20px;

    > svg {
        color: var(--dark-100, #4b5168);

        text-align: center;
        font-family: Font Awesome 6 Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.blue {
    color: var(--primary-600, #476ce2) !important;
    text-align: center;
    font-family: Font Awesome 6 Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.green {
    color: var(--success-700, #23a866) !important;
    text-align: center;
    font-family: Font Awesome 6 Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.red {
    color: var(--Error-600, #d13e2c) !important;
    text-align: center;
    font-family: Font Awesome 6 Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.normal-font{
    text-align: center;
    font-family: Font Awesome 6 Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}