// .modal-dialog {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;

//     width: 100%;
//     height: 100%;
// }

// .modal-content {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 16px;
//     gap: 24px;

//     position: relative;
//     width: 500px;

//     background: #FFFFFF;

//     border: 1px solid #C6C8CE;

//     box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14);
//     border-radius: 8px;
// }

.confirmationDialogVerticalWrapper {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.confirmationDialogHorizontalWrapper {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    overflow-y: auto;
}

.edged {
    align-content: space-between;
}

.reversed {
    flex-direction: row-reverse;
}

.title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    display: flex;
    align-items: center;

    cursor: default;

    color: #1f2747;
}

.description {
    width: 100%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: normal;

    cursor: default;

    color: #1f2747;

    > .description-container {
        display: flex;
        align-items: flex-start;
        gap: 2px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        cursor: default;

        color: #1f2747;
    }
}
