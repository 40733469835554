.cluster {
    display: flex;
    align-items: center;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.table-row-column {
    display: flex;
    height: 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    > svg {
        color: #1f2747;
        text-align: center;
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.spin-animation {
  display: inline-block;
  animation: spin 2s linear infinite;
}

.noGap {
    gap: 0;
}

.shortGap {
    gap: 4px;
}

.normalGap {
    gap: 8px;
}

.longGap {
    gap: 16px;
}

.icon-container {
    display: flex;
    width: 20px;
    align-items: center;
    justify-content: center;
}

.default-text {
    color: var(--Dark-100, #4b5168);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    padding: 0;

    > .searchBar {
        width: 100% !important;
    }
}

.vertical {
    flex-direction: column;
}

.pushApart {
    width: 100%;
    justify-content: space-betweeSetupn;
}

.verticalCluster {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.tagNameContainer {
    display: flex;
    flex-wrap: wrap;

    padding: 8px 0;
    gap: 4px;
}

.genericWrapper {
    width: 100%;
    height: 100%;
}

.circleTag {
    display: flex;
    min-width: 24px;
    min-height: 24px;
    justify-content: center;
    align-items: center;

    border-radius: 12px;

    background: #dd7549;

    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tagNameObject {
    display: flex;
    height: 24px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Light-300, #eaebee);

    gap: 4px;

    cursor: pointer;

    > .tagRemover {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 6px;
        justify-content: center;
        align-items: center;

        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        > svg {
            width: 12px !important;
            height: 12px !important;
            color: var(--Dark-100, #4b5168);
            text-align: center;
            font-family: "Font Awesome 6 Pro";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        &:hover {
            background: var(--Error-200, #f6d8d5);

            > svg {
                color: var(--Error-700, #a73223);
            }
        }
    }

    &:hover {
        background: var(--Light-400, #dfe0e3);
    }

    color: var(--Dark-100, #4b5168);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tagNameObjectNonInteractive {
    &:hover {
        background: var(--Light-300, #eaebee);
    }
}

.tagNameObjectRemove {
    padding: 2px 0 2px 8px;

    &:hover {
        background: var(--Light-300, #eaebee);
    }
}

.compositeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    // &:last-child {
    //     padding-bottom: 16px;
    // }
    align-self: stretch;
}
