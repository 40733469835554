.tableWrapper {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    gap: 0 !important;

    > .tableHeader {
        display: flex;

        height: 56px;
        width: 100%;
    }
}

.tableBodyWrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: calc(100vh - 52px);

    gap: 16px;
    padding: 16px;

    // overflow-x: auto;

    > .tableSubHeader {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        > .tableSubHeaderGroup {
            display: flex;
            flex-direction: row;
            align-items: center;

            gap: 4px;

            > svg {
                display: flex;
                width: 20px;
                height: 16px;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                color: var(--Light-500, #c6c8ce);
                text-align: center;
                font-family: "Font Awesome 6 Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

.userfulTable {
    overflow: auto;

    width: 100%;
    // height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border: 1px solid var(--Light-500, #c6c8ce);
    border-radius: 8px;

    align-self: stretch;

    // box-shadow: 0px 2px 2px 0px rgba(31, 39, 71, 0.2);

    > thead {
        display: flex;
        width: 100%;

        > tr {
            display: flex;
            width: 100%;
            height: 40px;
            justify-content: center;
            align-items: center;

            > td {
                display: flex;
                height: 40px;
                padding: 0px 24px;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                // border-top: 1px solid var(--Light-500, #c6c8ce);
                border-bottom: 1px solid var(--Light-500, #c6c8ce);
                background: var(--Light-200, #f3f4f5);

                cursor: pointer;

                color: var(--Dark-100, #4b5168);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    > tbody {
        width: 100%;
        height: 100%;
        > tr {
            display: flex;
            width: 100%;
            min-height: 40px;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            border-bottom: 1px solid var(--light-500, #c6c8ce);

            &:last-child{
                border-bottom: none;
            }

            &:nth-child(2n) {
                background: var(--Light-100, #f7f9fa);
            }

            &:hover {
                background: var(--Light-200, #f3f4f5);
            }

            > td {
                display: flex;
                padding: 0px 24px;
                align-items: center;
                gap: 8px;
                align-self: stretch;

                &:first-child {
                    border-left: none;
                }

                color: var(--dark-400, #1f2747);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}


.nonInteractive {
    cursor: default !important;

    &:hover {
    }
}

.userfulTableActions{
    position: absolute;
}

.userfulTableActionsToggle {

    display: flex;
    height: 36px;
    width: 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border: none;
    background: transparent;

    > svg {
        color: #1f2747;

        text-align: center;
        font-family: "Font Awesome 6 Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &:hover {
        background: var(--light-200, #f3f4f5);
        border: none;
        box-shadow: none;
        outline: none;
    }

    &:active {
        background: var(--light-200, #f3f4f5);
        border: none;
        box-shadow: none;
        outline: none;
    }

    &:focus {
        background: var(--light-200, #f3f4f5);
        border: none;
        box-shadow: none;
        outline: none;
    }
}
