.operationCenterDropdown {
    // z-index: 10;
}

.dropdownButtonSwitch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 4px 16px;
    gap: 8px;

    width: 100%;
    min-width: 340px;
    height: 36px;

    border-radius: 8px;

    background-color: white;
    border: none;

    >.textInside {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
   
        display: flex;
        align-items: center;

        width: 100%;

        color: #1F2747;

        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }

    >svg {
        width: 14px;
        height: 16px;

        font-family: 'Font Awesome 6 Pro';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        /* Dark/100 */

        color: #4B5168;
    }

    &:hover {
        background: #EAEBEE;
        border: none;
    }

    &:active {
        background: #EAEBEE;
        border: none;
        box-shadow: none;
        outline: none;
    }

    &:focus {
        background: #EAEBEE;
        border: none;
        box-shadow: none;
        outline: none;
    }
}

.dropdownMenuSwitch {

    margin: 4px 0px;
    padding: 4px 0px;

    width: auto;

    background: #FFFFFF;
    border: 1px solid #C6C8CE;

    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 8px;

    >hr {

        height: 0;
        margin: 4px 0;

        border: 1px solid #C6C8CE;
    }

    >.dropdownItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        gap: 16px;

        height: 30px;

        background: #FFFFFF;

        color: #1F2747;

        svg {
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            // width: 16px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;

            /* Dark/400 */

            color: #1F2747;
        }

        >.textInside {
            width: auto;
            height: 17px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            color: #1F2747;
        }

        &:hover {
            background: #EAEBEE;

            color: #1F2747;
        }

        &:active {
            background: #F3F4F5;

            color: #1F2747;
        }
    }
}

.dropdownMenuSwitchShort {

    padding: 8px 0px;

    width: 280px;

    background: #FFFFFF;
    border: 1px solid #C6C8CE;

    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 8px;

    >hr {

        height: 0;
        margin: 8px 0;

        border: 1px solid #C6C8CE;
    }

    >.dropdownItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 16px;
        gap: 16px;

        height: 30px;

        background: #FFFFFF;

        color: #1F2747;

        >svg {
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;

            /* Dark/400 */

            color: #1F2747;
        }

        >.textInside {
            width: 300px;
            height: 17px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            color: #1F2747;
        }

        &:hover {
            background: #EAEBEE;

            color: #1F2747;
        }

        &:active {
            background: #F3F4F5;

            color: #1F2747;
        }
    }
}