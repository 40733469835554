* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
html,
body {
    font-family: Inter, sans-serif;
    height: 100%;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.root{
    position: relative;
}

.App {
    height: 100%;
}

.fullscreen {
    background-color: var(--userful-white);
}

.order1 {
    order: 1;
}

.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.search {
    top: 0px !important;
}

.active {
    background: var(--userful-primary-blue-light);
}

.flex {
    display: flex !important;
    align-items: center;
}

.hero-wrapper {
    >div {
        display: flex;
        justify-content: space-between;
    }

    .tab-button {
        font-weight: 600;
        padding: 8px 16px;
        background: var(--userful-primary-blue-light);
        border-radius: 8px;
        border: none;
        margin-top: 24px;
        color: var(--userful-primary-blue);
    }

    .hero-text-wrapper {
        width: 460px;
        max-width: 460px;
    }

    .custom-close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        cursor: pointer;

        &:hover {
            border-radius: 8px;
            background: var(--userful-secondary);
        }
    }
}

.react-calendar {
    border-radius: 8px;
}

input.react-datetimerange-picker__inputGroup__input {
    &:focus-visible {
        outline: none;
    }
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive {
    // background: var(--userful-primary-blue-light) !important;
    // border-radius: 8px;
}

.offcanvas.show {
    width: 601px !important;
    overflow-y: auto;
}

.offcanvas-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px;
}

.main-container {
    padding: 0px;
}

.success-btn {
    font-weight: 600;
    padding: 0px 16px;
    max-height: 36px;
    outline: none;
    border: none;
    background: var(--userful-green);
    border-color: var(--userful-green);
    border-radius: 8px;
    color: var(--userful-white);

    &:hover {
        background: #2CD280;
        border-color: #2CD280;
    }
}

.primary-btn {
    background: var(--userful-primary-blue);
    border-radius: 8px;
    font-weight: 600;
    color: var(--userful-white);
    outline: none;
    border: none;
    height: 36px;
    padding: 0px 16px !important;

    &:hover {
        background: #476CE2;
    }
}

.secondary-btn {
    background: var(--userful-secondary);
    border-radius: 8px;
    color: var(--userful-primary-dark);
    border: 1px solid var(--userful-border-secondary);
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: var(--userful-secondary-dark);
    }
}

.button-danger {
    font-weight: 600;
    padding: 0px 16px;
    background: var(--userful-alert);
    outline: none;
    border: none;
    color: var(--userful-white) !important;
    max-height: 36px;
    height: 36px;
    color: var(--userful-primary-dark);
    border-radius: 8px;

    &:hover {
        background: #DA6556;
    }
}

.pagination {
    .page-item {
        // border: 1px solid var(--userful-border-secondary);
        // border-radius: 8px;
    }

    .page-item.active {
        .page-link {
            background: var(--userful-primary-blue);
            border: 1px solid var(--userful-primary-blue);
            box-sizing: border-box;
            border-radius: 8px;
        }
    }
}

.table-actions {
    margin-bottom: -35px;

    .table-actions-left {
        .dropdown {
            border-radius: 8px;
            cursor: pointer;

            >div {
                border: 1px solid var(--userful-border-secondary);
            }
        }

    }
}

.dropdown {
    cursor: pointer;

    .custom-dropdown-menu {
        padding: 8px 0px;
        background: var(--userful-white);
        border: 1px solid var(--userful-border-secondary);
        box-sizing: border-box;
        box-shadow: 0px 8px 10px rgba(116, 101, 101, 0.14);
        border-radius: 8px;
        margin-top: 2px;

        ul {
            padding: 0px;
            margin: 0px;

            .dropdown-item {
                color: var(--userful-primary-dark);
                display: flex;
                align-items: center;
                padding: 0px 16px;
                height: 32px;
                cursor: pointer;

                &:hover {
                    background: var(--userful-secondary);
                }

                &:active {
                    background: var(--userful-secondary);
                }

                svg,
                img {
                    margin-right: 16px;
                }
            }
        }

        hr {
            margin: 8px 0px;
        }

    }
}

.custom-dropdown-toggle {
    background: var(--userful-secondary);
    border-radius: 8px;
    padding: 0px 16px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--userful-primary-dark);
    font-weight: 600;



    &:hover {
        background-color: var(--userful-secondary-dark);
    }
}

.dropdown {
    .dropdown-toggle::after {
        display: none !important;
    }
}

input[type="checkbox"] {
    border: 1px solid var(--userful-border-secondary);
    box-sizing: border-box;
    box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
    border-radius: 4px;
    width: 16px;
    height: 16px;
}

input[type="checkbox"]:checked {
    background-color: var(--userful-primary-blue) !important;
    color: var(--userful-white);

    &:focus {
        box-shadow: none;
        border: 1px solid var(--userful-border-secondary) !important;
    }
}

.form-check-input:focus {
    border: 1px solid var(--userful-border-secondary) !important;
    outline: none !important;
    box-shadow: none !important;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    color: #476CE2;
    cursor: pointer;
}

.info-heading {
    font-weight: 600;
    color: var(--userful-primary-dark);
    margin-bottom: 4px;
}

.toggle-switch {
    >:nth-child(2) {
        height: 12px !important;
        width: 12px !important;
        top: 2px !important;
    }
}

.source-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    position: relative;
    overflow-y: auto;

    >div:not(:last-child) {
        margin-bottom: 16px;
    }

    .table-actions {
        display: flex;
        justify-content: space-between;

        .table-actions-left {
            display: flex;

            >div:not(:first-child) {
                margin-left: 8px;
            }
        }

        // .table-actions-right {
        //     display: flex;


        //     >button:not(:first-of-type) {
        //         margin-left: 8px;
        //     }
        // }

        .server-options {
            min-width: 229px;

            >div {
                padding: 0px 16px;
            }
        }
    }

    .table-search {
        position: absolute !important;
        top: 17px;
    }

    .bread-crumb {
        a {
            text-decoration: none;
            color: #476CE2;
            font-weight: 500;
        }
    }

    .breadcrumb-item.active {
        color: var(--userful-primary-dark);
        font-weight: 500;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        font-weight: 500;
    }

}

.source-creation-wrapper {
    margin: 0px;
    // width: 590px;
}

.mappingContentNavWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .mappingContentNav {
        display: flex;
        flex: 1;
        flex-direction: column;
        // align-items: center;
        width: 100%;
        height: 100%;
        z-index: 10;
        position: relative;
        .main-content-container {
            flex: 1;
            overflow-y: auto;


        }

        .divWrapper {
            height: 100%;

            .sourceDropZone {
                height: 100%;
                width: 100%;
            }
        }
    }

    .destinationsDropZone {
        width: 100%;
        padding: 10px 15px;
        border-top: 1px solid var(--userful-border-secondary);
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            background: var(--userful-secondary);
            border-radius: 8px;
        }
    }

    .mappingContentMain {
        border-bottom: 1px solid var(--userful-border-secondary);
        height: 56px;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .contentMainLt {
            >div:not(:last-child) {
                margin-right: 8px;
            }

            .eventNameBtn {
                padding: 8px 16px;
                font-weight: 500;
                font-size: 16px;
            }
        }

        .contentMainRt {
            display: flex;

            >div:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .mappingContentTab {
        border: 1px solid var(--userful-border-secondary);
        border-radius: 8px;

        >.nav-item {
            >.nav-link {
                color: var(--userful-primary-dark);
                border: 1px solid var(--userful-border-secondary);

                &:hover {
                    background: var(--userful-secondary);
                    border: 1px solid var(--userful-border-secondary);
                    box-sizing: border-box;
                    border-radius: 8px 0px 0px 8px;
                }
            }

            >button {
                //  padding: 8px 16px;
            }

            .active {
                background: var(--userful-primary-blue-light);
                color: var(--userful-primary-blue);
                border: 1px solid var(--userful-primary-blue);
            }
        }
    }

    .fstBtn {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .secBtn {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .lstBtn {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .eventNameBtn {
        background: none;
        border: none;
        margin-right: 8px;
    }

    .active {
        background: var(--userful-primary-blue-light);
        border: 1px solid var(--userful-primary-blue);
        color: var(--userful-primary-blue);

        &:hover {
            background-color: var(--userful-primary-blue-light);
        }
    }
}

.multiWindowBottomContainer {
    >.divWrapper {
        border-top: solid 1px var(--userful-border-secondary);

        >.destinationsDropZone {
            width: 100% !important;

            >.dropZone {
                width: 100%;
            }

            >.dragginDestinationOverlay {
                top: 10px;
                bottom: 10px;
                left: 15px;
                right: 15px;
                padding: 16px;
                width: unset;
            }
        }
    }
}

.scheduleWrapperContainer .mappingContentNavWrapper .mappingContentNav {
    height: unset !important;
}

.tabCustomBtn {
    background: var(--userful-white);
    border-radius: 8px;
    color: var(--userful-primary-dark);
    border: 1px solid var(--userful-border-secondary);
    height: 36px;
    padding: 0px 16px;
    font-weight: 500;

    &:hover {
        background: var(--userful-secondary);
    }
    &:disabled{
        cursor: not-allowed;
        opacity: 0.65;
        &:hover{
            background: var(--userful-white) !important;
        }
    }
}

.hideDestinationBorder {
    font-weight: 500;
    margin: 0px !important;
    border-radius: 8px;
    border: 1px solid var(--userful-border-secondary);
    height: 36px;
    padding: 0px 16px;
    font-weight: 500;
    background: var(--userful-primary-blue-light);
    border: 1px solid var(--userful-primary-blue);
    color: var(--userful-primary-blue);
    padding-top: 8px;
}

.sourceContentContainer {
    .app-search {
        position: absolute;
        top: 16px;
    }
}

.upload-files-area {
    width: 100%;
}

// css for Select component from 'react-select'. it is a specific class inside div component
.css-19bb58m {
    padding: 0px !important;
}

.css-1dimb5e-singleValue {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.customDropdownSDMW{
    height: 32px;

    >div{
        display: flex;
        align-items: center;
        height: 100%;
    }
}